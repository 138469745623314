export default {
  application: {
    title: "Tutorial",
    description: "Help"
  },
  cdn: {
    URL: "https://d1v4qhkg42nng1.cloudfront.net"
  },
  api: {
    URL: "https://pawjsn3z0i.execute-api.eu-west-2.amazonaws.com/tutorial"
  },
  authentication: {
    AUTH_CLIENT: "<auth_client>"
  }
}